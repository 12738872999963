import Image from "next/image";
import styles from "./styles.module.scss";
import {Heading, DisplayText, Subheading} from "@shopify/polaris";
import useTranslation from "next-translate/useTranslation";
import { HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";

const HomeHero = () => {
  const {t, lang} = useTranslation(HOME_NAMESPACE);
  return (
    <section className={styles["hero"]}>
      <header className={styles["hero__header"]}>
        <DisplayText size="extraLarge" element="h2">
          {t("hero_header")}
        </DisplayText>
      </header>
      <div className={styles["hero__text-wrapper"]}>
        <p className={styles["hero__text"]}>{t("hero.text")}</p>
      </div>
      <div className={styles["hero__desc-wrapper"]}>
        <p className={styles["hero__desc"]}>{t("hero_descText")}</p>
      </div>
    </section>
  )
}

export default HomeHero;