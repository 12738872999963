import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { Collapsible } from "@shopify/polaris";
import Arrow from "../Arrow";
import cn from "classnames";

const CollapsibleItem_Main = ({
  bgColor = "#FFFFFF",
  title,
  desc,
  arrowColor,
  textColor,
  titleReverse,
  textHighlight,
  highlightColor,
  isOpen,
  toggle,
  overControl,
  element,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleToggle = useCallback(() => {
    setOpen((open) => !open);
    toggle && toggle();
  }, [isOpen]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div
      className={cn(styles["collapsible"], {
        [styles["collapsible--active"]]: open,
      })}
    >
      <button
        style={{
          color: highlightColor,
          backgroundColor: bgColor,
        }}
        className={cn(
          styles["collapsible__activator"],
          { [styles["activator--reverse"]]: titleReverse },
          { [styles["activator--text-highlight"]]: textHighlight && open }
        )}
        onClick={handleToggle}
      >
        <span
          className={styles["collapsible__activator-text"]}
          style={{ color: textColor }}
        >
          {title}
        </span>
        <span className={styles["collapsible__arrow-wrapper"]}>
          <Arrow color={arrowColor} />
        </span>
      </button>
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{ duration: "200ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <div
          className={cn(styles["collapsible__desc-wrapper"], {
            [styles["desc--reverse"]]: titleReverse,
          })}
        >
          {element ? (
            element
          ) : (
            <p
              className={styles["collapsible__desc"]}
              style={{ color: textColor }}
              dangerouslySetInnerHTML={{ __html: desc }}
            ></p>
          )}
        </div>
      </Collapsible>
    </div>
  );
};

CollapsibleItem_Main.defaultProps = {
  arrowColor: "#008060",
  textColor: "#000",
  titleReverse: false,
  textHighlight: false,
  highlightColor: "#008060",
  toggle: null,
  open: false,
};

CollapsibleItem_Main.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  arrowColor: PropTypes.string,
  textColor: PropTypes.string,
  titleReverse: PropTypes.bool,
  textHighlight: PropTypes.bool,
  highlightColor: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default CollapsibleItem_Main;
