import Image from "next/image";
import {useMemo} from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import {Button, Stack, Icon, Tooltip} from "@shopify/polaris";
import {
  AppExtensionMinor, ConversationMinor, TeamMajor, ProfileMajor,
  ActivitiesMajor, StoreMajor, ThemesMajor
} from '@shopify/polaris-icons';
import {APP_STORE_LINK, THEME_STORE_LINK} from "../../../../utils/constants";
import useTranslation from "next-translate/useTranslation";
import _1 from "/public/home/aboutUs/_1.png";
import _2 from "/public/home/aboutUs/_2.png";
import _3 from "/public/home/aboutUs/_3.png";
import _4 from "/public/home/aboutUs/_4.png";
import _5 from "/public/home/aboutUs/_5.png";
import _6 from "/public/home/aboutUs/_6.png";
import _7 from "/public/home/aboutUs/_7.png";
import CollapsibleItem_Main from "../../../common/elements/CollapsibleItem_Main";
import {HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";

const iconsArr = [TeamMajor, ActivitiesMajor, ProfileMajor, ThemesMajor, AppExtensionMinor, ConversationMinor];
const iconColor = "#ffffff";
const AboutUs = () => {
  const {t, lang} = useTranslation(HOME_NAMESPACE);

  const aboutUs = useMemo(() => {
    return {
      list: [
        {
          header: t("aboutUs._1.header"),
          descText: t("aboutUs._1.descText"),
          src: "/polarisIcons/team.svg",
          alt: "Professional team"
        },
        {
          header: t("aboutUs._2.header"),
          descText: t("aboutUs._2.descText"),
          src: "/polarisIcons/experience.svg",
          alt: "8+ years of experience"
        },
        {
          header: t("aboutUs._3.header"),
          descText: t("aboutUs._3.descText"),
          src: "/polarisIcons/user.svg",
          alt: "6,000+ app users"
        },
        {
          header: t("aboutUs._4.header"),
          descText: t("aboutUs._4.descText"),
          src: "/polarisIcons/themes.svg",
          alt: "Shopify Theme developer"
        },
        {
          header: t("aboutUs._5.header"),
          descText: t("aboutUs._5.descText"),
          src: "/polarisIcons/algorithm.svg",
          alt: "Own ML algorithm"
        },
        {
          header: t("aboutUs._6.header"),
          descText: t("aboutUs._6.descText"),
          src: "/polarisIcons/support.svg",
          alt: "24/7 support"
        }
      ]
    }
  }, [t])

  const halfNumber = Math.ceil(aboutUs.list.length / 2);

  const leftList = useMemo(() => {
    return (
      <ul className={styles["about__list"]}>
        {aboutUs.list.map((element, i) => {
          if (i >= halfNumber) {
            return;
          }
          return (
            <li key={i} className={cn(styles["about__item"], styles[`item-left`], `item-left`)}>
              <div className={styles["about__item-header-wrapper"]}>
                <h3 className={styles["about__item-header"]}>{aboutUs.list[i].header}</h3>
                <div className={styles["about__icon-wrapper"]} style={{width: 20, height: 20}}>
                  <Icon source={iconsArr[i]} color={iconColor}/>
                </div>
              </div>
              <p className={styles["about__item-desc"]}>{aboutUs.list[i].descText}</p>
            </li>
          )
        })}
      </ul>
    );
  }, [t])

  const rightList = useMemo(() => {
    return (
      <ul className={styles["about__list"]}>
        {aboutUs.list.map((element, i) => {
          if (i < halfNumber) {
            return;
          }
          return (
            <li key={i} className={cn(styles["about__item"], styles[`item-right`], `item-right`)}>
              <div className={styles["about__item-header-wrapper"]}>
                <div className={styles["about__icon-wrapper"]} style={{width: 20, height: 20}}>
                  <Icon source={iconsArr[i]} color={iconColor}/>
                </div>
                <h3 className={styles["about__item-header"]}>{aboutUs.list[i].header}</h3>
              </div>
              <p className={styles["about__item-desc"]}>{aboutUs.list[i].descText}</p>
            </li>
          )
        })}
      </ul>
    );
  }, [t])


  return (
    <section className={cn(styles["about"], "about")}>
      <header className={styles["about__header"]}>
        <h3 className={styles["about__header-text"]}>{t("aboutUs.subheader")}</h3>
      </header>
      <div className={cn(styles["about__content"], "about__content")}>
        {leftList}
        <div className={styles["about__img-wrapper"]}>
          <div className={styles["about__img--1"]}>
            <Image src={_1} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--2"]}>
            <Image src={_2} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--3"]}>
            <Image src={_3} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--4"]}>
            <Image src={_4} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--5"]}>
            <Image src={_5} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--6"]}>
            <Image src={_6} quality={100} alt={"About us"}/>
          </div>
          <div className={styles["about__img--7"]}>
            <Image src={_7} quality={100} alt={"About us"}/>
          </div>
        </div>
        {rightList}
      </div>
      <div className={styles["about__content--mobile"]}>
        {
          aboutUs.list.map((el, i) => (
            <CollapsibleItem_Main key={i} title={el.header} desc={el.descText} textColor="#fff" arrowColor="#fff"/>
          ))
        }
      </div>
      <div className={cn(styles["about__img-wrapper--mobile"], "about__content")}>
        <div className={cn(styles["about__img--1"], styles["about__img"])}>
          <Image src={_1} quality={100} alt={"About us"} layout={"responsive"}/>
        </div>
        <div className={cn(styles["about__img--2"], styles["about__img"])}>
          <Image src={_2} quality={100} alt={"About us"} layout={"responsive"}/>
        </div>
        <div className={cn(styles["about__img--3"], styles["about__img"])}>
          <Image src={_3} quality={100} alt={"About us"} layout={"responsive"}/>
        </div>
        <div className={cn(styles["about__img--4"], styles["about__img"])}>
          <Image src={_4} quality={100} alt={"About us"} layout={"responsive"}/>
        </div>
      </div>
      <footer className={styles["about__footer"]}>
        <Stack alignment="center" distribution="center">
          <Tooltip content={t("header:theme-tooltip")} active={false}>
            <span className={"tooltip-wrapper"}>
              <Button icon={ThemesMajor} external disabled>{t("header:shopifyThemeStore")}</Button>
            </span>
          </Tooltip>
          <div className={"app-store-btn"}>
            <Button icon={StoreMajor} external url={APP_STORE_LINK} outline monochrome>{t("header:shopifyApp")}</Button>
          </div>
        </Stack>
      </footer>
    </section>
  );
};

export default AboutUs;