import HomeHero from "./HomeHero";
import AboutUs from "./AboutUs";
import Experts from "./Experts";
import dynamic from "next/dynamic";
// import AppDev from "./AppDev";
// import Reviews from "./Reviews";
// import ThemeDev from "./ThemeDev";
// import SingleReview from "../../blocks/SingleReview";
// import Departments from "./Departments";
// import CTA from "./CTA";

const Reviews = dynamic(() => import("./Reviews"))
const Departments = dynamic(() => import("./Departments"))
const AppDev = dynamic(() => import("./AppDev"));
const ThemeDev = dynamic(() => import("./ThemeDev"));
const SingleReview = dynamic(() => import("../../blocks/SingleReview"));
const CTA = dynamic(() => import("./CTA"));

const Home = ({testimonials}) => {
  return (
    <>
      <HomeHero/>
      <Experts/>
      <AboutUs/>
      <AppDev/>
      <Reviews testimonials={testimonials}/>
      <ThemeDev/>
      <SingleReview/>
      <Departments/>
      <CTA/>
    </>
  )
}
export default Home;