
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Head from 'next/head'
import Home from "../components/sitePages/Home";
import React, {useContext, useEffect} from "react";
import useTranslation from "next-translate/useTranslation";
import {HOME_NAMESPACE} from "../utils/i18nNameSpaces";
import {TESTIMONIALS_CONTEXT} from "../components/providers/TestimonialsProvider";
import {getAllTestimonials} from "../helpers/testimonials/getAllTestimonials";

export default function Index({testimonials, testimonialsArr}) {
  const {t} = useTranslation(HOME_NAMESPACE);
  const {addTestimonials} = useContext(TESTIMONIALS_CONTEXT);

  useEffect(() => {
    testimonialsArr.map(testimonialInfo =>
      addTestimonials(testimonialInfo.cacheKey, testimonialInfo.data)
    )
  }, [testimonialsArr])

  return (
    <>
      <Head>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.desc")}/>
      </Head>
      <Home testimonials={testimonials}/>
    </>
  );
}

 async function _getStaticProps() {
  return await getAllTestimonials();
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  