import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import {Button, Tooltip} from "@shopify/polaris";
import ShopifyExperts from "../../../common/components/Icons/ShopifyExperts";
import {HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";


const Experts = () => {
  const {t} = useTranslation(HOME_NAMESPACE)

  return (
    <div className={styles["experts"]}>
      <div className={styles["experts__container"]}>
        <div className={styles["experts__img-wrapper"]}>
         <ShopifyExperts/>
        </div>
        <div className={styles["experts__content"]}>
          <p className={styles["experts__auth-text"]}>{t("experts.authText")}</p>
          <p className={styles["experts__text"]}>{t("experts.text")}</p>
          <div className={styles["btn-wrapper"]}>
            <Tooltip content={t("header:theme-tooltip")} active={false}>
            <span className={"tooltip-wrapper"}>
              <Button url="https://experts.shopify.com/" external disabled fullWidth>{t("experts.link")}</Button>
            </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;